<template>
  <v-card class="pa-2">
    <v-data-iterator
      item-key="IdCliente"
      :items="value"
      :footer-props="{
        showCurrentPage: true,
      }"
      :search="search"
    >
      <template v-slot:header>
        <div class="d-flex align-center">
          <v-subheader>Cliente comprometidos pago</v-subheader>
          <v-spacer />
          <v-text-field
            dense
            hide-details
            filled
            prepend-inner-icon="mdi-magnify"
            v-model="search"
          />
          <v-btn
            color="primary"
            icon
            class="ml-1"
            text
            @click.stop="$emit('exportar', value, 'clientesComprometidosPago')"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </div>
        <v-divider />
        <v-alert v-if="alertInfo" class="ma-2" dense text type="info">
          <small>Impagados actuales + pagos aplazados</small>
        </v-alert>
      </template>

      <template v-slot:default="{ items }">
        <v-list dense>
          <v-list-item v-for="item in items" :key="item.IdCliente">
            <v-list-item-content>
              <div class="d-flex">
                <v-btn
                  text
                  color="secondary"
                  small
                  :to="`/clientes/detalles?idCliente=${item.IdCliente}`"
                >{{ item.denominacion }}</v-btn>
              </div>
            </v-list-item-content>
            <v-list-item-action>
              <div class="d-flex align-center">
                <v-chip :color="getSituacionIcon('Comprometido Pago').color" label small outlined class="v-chip--activated">
                    {{ item.deudaComprometida }}
                </v-chip>
                <div class="mx-1">/</div>
                <v-chip :color="getSituacionIcon('Impagado').color" label small outlined class="v-chip--activated">
                    {{ item.deudaTotal }}
                </v-chip>
                  </div>
                <v-chip small label outlined class="v-chip--activated mt-1">
                  {{ parseFloat(item.deudaComprometida * 100 / item.deudaTotal).toFixed(2) }} %
                </v-chip>
              
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
    </v-data-iterator>
  </v-card>
</template>

<script>
import { getSituacionIcon } from "@/utils/index.js";

export default {
  props: {
    value: { type: Array, optional: false },
    alertInfo: { type: Boolean, default: false }
  },
  data() {
    return {
      search: null
    }
  },
  methods: {
    getSituacionIcon
  }
};
</script>

<style scoped>
.v-data-iterator >>> .v-data-footer__select {
  display: none !important;
}
.v-data-iterator >>> .v-data-footer__pagination {
  margin-left: auto !important;
}
</style>